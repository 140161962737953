
import { computed, defineComponent, ref } from "vue";
import { useValidation } from "vue3-form-validation";
import { useI18n } from "vue-i18n";
import { getImage, numberSeparators, trim } from "@/graphql/utils/utils";
import ImageCropper from "@/components/common/ImageCropper.vue";

type Country = {
  label: string;
  currency: string;
  separator: number;
  symbol: string;
  value: number;
};
export default defineComponent({
  name: "ActivityForm",
  props: ["item", "loading", "modelValue"],
  emits: ["submit", "update:modelValue"],
  components: { ImageCropper },
  setup(props, { emit }) {
    const { tm } = useI18n();
    const { form, hasError, validateFields } = useValidation({
      name: {
        $value: "",
        $rules: [(n: string) => !n && ""],
      },
      slogan: {
        $value: "",
        $rules: [(n: string) => n.length > 300 && ""],
      },
      address: {
        $value: "",
        $rules: [(n: string) => (!n || n.length > 255) && ""],
      },
      currency: {
        $value: "",
        $rules: [(n: string) => !n && ""],
      },
      currencySymbol: {
        $value: "",
        $rules: [(n: string) => !n && ""],
      },
      decimalNumber: {
        $value: 0,
        $rules: [(n: any) => String(n).length !== 1 && ""],
      },
      taxRate: {
        $value: 0,
        $rules: [(n: any) => String(n).length < 1 && ""],
      },
      country: {
        $value: null,
        $rules: [(n: any) => n === null && ""],
      },
      separator: 0,
      removeImage: {
        $value: false,
      },
    });
    const countries: Country[] = (tm("activity.countries") as any[]).map(
      (item, value) => ({
        ...item,
        separator: Number(item.separator),
        value,
      })
    );
    const selectedCountry = ref<Country>(null);

    const image = ref<File>(null);
    const cropperRef = ref<any>(null);

    function atShow() {
      if (props.item) {
        form.name.$value = props.item.name;
        form.slogan.$value = props.item.slogan;
        form.address.$value = props.item.address;
        form.country.$value = props.item.country;
        form.currency.$value = props.item.currency;
        form.currencySymbol.$value = props.item.currencySymbol;
        form.taxRate.$value = props.item.taxRate;
        form.separator = props.item.separator;
        form.decimalNumber.$value = props.item.decimalNumber;
        form.removeImage.$value = false;
        image.value = null;
      }
      selectedCountry.value = countries.find(
        (c) => c.value === form.country.$value
      );
    }

    function onSelectCountry(country: Country) {
      form.country.$value = country.value;
      form.separator = country.separator;
      form.currencySymbol.$value = country.symbol;
      form.currency.$value = country.currency;
    }

    function submit() {
      validateFields().then(async (input) => {
        await cropperRef.value.getResult();
        trim(input);
        if (props.item) {
          Object.assign(input, { id: props.item.id });
          emit("submit", [image.value, input]);
        } else {
          const { removeImage, ...res } = input;
          emit("submit", [image.value, res]);
        }
      });
    }

    return {
      countries,
      selectedCountry,
      onSelectCountry,
      form,
      hasError,
      cropperRef,
      submit,
      separators: computed(() => [
        {
          label: tm("activity.separator"),
          items: (tm("format.separators") as string[]).map((label, index) => ({
            label,
            disabled: index === form.separator,
            command: () => (form.separator = index),
          })),
        },
      ]),
      previewFormat: ref(1000000),
      numberSeparators,
      atShow,
      image,
      logo: computed(() => {
        const lg = props.item?.logo;
        const ok = props.item && lg !== "" && lg !== JSON.stringify("");
        return {
          src: form.removeImage.$value
            ? ""
            : ok
            ? getImage(lg, "activity")
            : "",
          ok,
        };
      }),
    };
  },
});
